//
// Code
//

code:not([class*='language-']) {
  font-weight: $code-font-weight;
  color: var(--kt-code-color);
  line-height: inherit;
  font-size: inherit;
  background-color: var(--kt-code-bg);
  padding: $code-padding;
  margin: $code-margin;
  box-shadow: var(--kt-code-box-shadow);
  @include border-radius($code-border-radius);
}
